<template>
  <section class="relative mt-8">
    <div v-if="loading" class="rounded-md icono-carga p-8 pos-loading">
      <LoadingIcon icon="puff" color="rgba(0,0,0,0.4)" />
    </div>
    <div class="intro-y flex items-center h-10">
      <h2 class="text-lg font-medium truncate mr-5">Procesos judiciales</h2>
    </div>
    <div class="box w-full p-5 mt-12 sm:mt-5" style="overflow:hidden;">
      <div class="flex justify-between">
        <div class="flex items-center mx-4">
          <div>
            <Button @click="getData(1)" class="bg-white border-0 text-gray-600" icon="pi pi-angle-left p-0" />
          </div>
          <div class="mx-2 font-bold capitalize">
            {{ tipeDateSelected === 1 ? dateSelected.locale('es').format('MMMM - YYYY') : dateSelected.locale('es').format('YYYY') }}
          </div>
          <div>
            <Button @click="getData(2)" class="bg-white border-0 text-gray-600" icon="pi pi-angle-right" />
          </div>
        </div>
        <Dropdown class="md:ml-auto mt-5 md:mt-0" v-model="tipeDateSelected" @change="getData()" :options="tipeDate" optionLabel="name" optionValue="value" placeholder="Seleccione" />
      </div>
      <div
      id="grafica"
      ref="elGrafica"
      style="height:500px;"
      class="w-full mt-4"
      />
      <div class="flex justify-between mt-4">
          <div>
            <p>
              Total de Procesos Judiciales en el
              <span v-if="tipeDateSelected === 1">mes</span>
              <span v-if="tipeDateSelected === 2">año</span>
              de
              <span class="capitalize font-bold">{{ tipeDateSelected === 1 ? dateSelected.locale('es').format('MMMM') : dateSelected.locale('es').format('YYYY') }}</span>
              es de <span class="capitalize font-bold">{{ total }}</span>
            </p>
          </div>
          <div>
            <button class="text-blue-600 flex items-center" @click="$router.push({name:'pharmasan.administrativa.juridica.tutelas'})">
              Ver todos
              <i class="pi pi-arrow-circle-right ml-1"></i>
            </button>
          </div>
        </div>
    </div>
  </section>
</template>

<script>
import dayjs from 'dayjs'
import * as echarts from 'echarts'
import { computed, onMounted, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import DashboardService from '../../../services/dashboard.service'
export default {
  name: 'ChartProcesosJudiciales',
  setup () {
    const _DashboardService = ref(new DashboardService())
    const store = useStore()
    const isDark = computed(() => {
      return store.getters['main/darkMode']
    })

    const gradesRotation = ref(0)
    const positionLabel = ref('insideTop')
    const distanceLabel = ref(10)
    const loading = ref(false)
    const colorLabel = ref('white')
    const elGrafica = ref(null)
    let chart = reactive({})
    const chartData = ref([])
    const dateSelected = ref(dayjs())
    const tipeDateSelected = ref(1)
    const dataProcesos = ref([])
    const total = ref('')
    const tipeDate = ref([
    {
        name: 'Mes',
        value: 1
      },
      {
        name: 'Año',
        value: 2
      }
    ])

    const getData = (action) => {
      if (tipeDateSelected.value === 1) {
        action === 1 ? dateSelected.value = dateSelected.value.subtract(1, 'month') : dateSelected.value = dateSelected.value.add(1, 'month')
      }
      if (tipeDateSelected.value === 2) {
        action === 1 ? dateSelected.value = dateSelected.value.subtract(1, 'year') : dateSelected.value = dateSelected.value.add(1, 'year')
      }
      initChart()
    }

    const initChart = () => {
      gradesRotation.value = (window.innerWidth < 1000) ? 90 : 0
      positionLabel.value = (window.innerWidth < 1000) ? 'top' : 'top'
      distanceLabel.value = (window.innerWidth < 1000) ? 25 : 10
      colorLabel.value = (window.innerWidth < 1000 && !isDark.value) ? 'gray' : 'gray'
      chart = echarts.init(elGrafica.value, isDark.value ? 'dark' : 'light')
      _DashboardService.value.getProcesosJudiciales(tipeDateSelected.value === 1 ? { month: dayjs(dateSelected.value).format('YYYY-MM') } : { year: dayjs(dateSelected.value).format('YYYY') }).then(({ data }) => {
        total.value = data.total.count
        const dataSeries = []
        const drilldownDataGrafica = []
        for (const i in data.clases) {
          dataSeries.push(
            {
              value: data.clases[i].map(item => item.Cantidad).reduce((prev, curr) => parseInt(prev) + parseInt(curr), 0),
              groupId: i
            }
          )
          drilldownDataGrafica.push(
            {
              dataGroupId: i,
              data: data.clases[i].map(a => {
                return [
                  a.Clase,
                  parseInt(a.Cantidad)
                ]
              })
            }
          )
        }
        dataProcesos.value = data.tipos
        const option = {
          xAxis: {
          },
          yAxis: {
            data: Object.keys(data.clases)
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          dataGroupId: '',
          animationDurationUpdate: 500,
          grid: {
            top: 80,
            bottom: 30,
            left: '25%'
          },
          series: {
            type: 'bar',
            id: 'sales',
            data: dataSeries,
            universalTransition: {
              enabled: true,
              divideShape: 'clone'
            }
          }
        }
        const drilldownData = drilldownDataGrafica
        chart.on('click', function (event) {
          if (event.data) {
            var subData = drilldownData.find(function (data) {
              return data.dataGroupId === event.data.groupId
            })
            const dataTitle = drilldownData.filter(data => data.dataGroupId === event.data.groupId)
            if (!subData) {
              return
            }
            chart.setOption({
              yAxis: {
                data: subData.data.map(function (item) {
                  return item[0]
                })
              },
              title: {
                text: dataTitle[0].dataGroupId,
                left: 'center'
              },
              series: {
                type: 'bar',
                id: 'sales',
                dataGroupId: subData.dataGroupId,
                data: subData.data.map(function (item) {
                  return item[1]
                }),
                universalTransition: {
                  enabled: true,
                  divideShape: 'clone'
                }
              },
              graphic: [
                {
                  type: 'text',
                  left: 50,
                  top: 20,
                  style: {
                    text: 'Atras',
                    fontSize: 18
                  },
                  onclick: function () {
                    chart.setOption(option, true)
                  }
                }
              ]
            })
          }
        })

        chart.setOption(option)
      })
    loadEvents()
    }
    const loadEvents = () => {
      window.addEventListener('resize', (evt) => {
        gradesRotation.value = (evt.target.innerWidth < 1000) ? 90 : 0
        positionLabel.value = (evt.target.innerWidth < 1000) ? 'top' : 'top'
        distanceLabel.value = (evt.target.innerWidth < 1000) ? 25 : 10
        colorLabel.value = (window.innerWidth < 1000 && !isDark.value) ? 'gray' : 'gray'
        reloadChart()
        chart.resize()
      })
    }

    const reloadChart = () => {
      const option = {
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01]
        },
        series: [
          {
            type: 'bar',
            data: dataProcesos.value.map(a => a.Cantidad)
          }
        ]
      }

      if (window.innerWidth < 1024) {
        option.xAxis.show = false
      }

      chart.setOption(option)
    }
    const setChartData = (values = []) => {
      chartData.value = values.data
    }

    onMounted(() => {
      initChart()
    })

    return {
      chart,
      loading,
      elGrafica,
      setChartData,
      reloadChart,
      getData,
      dateSelected,
      tipeDate,
      tipeDateSelected,
      total
    }
  }
}
</script>

<style>
.pos-loading {
  height:350px;
  position:absolute;
  top:50%;
  transform:translateY(-50%);
  z-index:1;
  width:100%
}
</style>
