import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_PROCESOS_JURIDICOS

export default class DashboardService {
    getTutelasByMonth (params) {
      return http.get(`${baseUrl}/dashboard/getTutelasByMonth`, {
        params,
        headers: {
          loading: true
        }
      })
    }

    getTutelasByYear (params) {
      return http.get(`${baseUrl}/dashboard/getTutelasByYear`, {
        params,
        headers: {
          loading: true
        }
      })
    }

    getDerechosPeticion (params) {
      return http.get(`${baseUrl}/dashboard/derechos-peticion`, {
        params,
        headers: {
          loading: true
        }
      })
    }

    getCardsInfo (params) {
      return http.get(`${baseUrl}/dashboard/getCardsInfo`, {
        params,
        headers: {
          loading: true
        }
      })
    }

    getProcedimientosAdministrativos (params) {
      return http.get(`${baseUrl}/dashboard/getProcedimientosAdministrativos`, {
        params,
        headers: {
          loading: true
        }
      })
    }

    getProcesosJudiciales (params) {
      return http.get(`${baseUrl}/dashboard/getProcesosJudiciales`, {
        params,
        headers: {
          loading: true
        }
      })
    }
}
