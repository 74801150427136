<template>
  <section class="relative mt-8">
    <div v-if="loading" class="rounded-md icono-carga p-8 pos-loading">
      <LoadingIcon icon="puff" color="rgba(0,0,0,0.4)" />
    </div>
    <div class="intro-y flex items-center h-10">
      <h2 class="text-lg font-medium truncate mr-5">Procedimientos Administrativos</h2>
    </div>
    <div class="box w-full p-5 mt-12 sm:mt-5" style="overflow:hidden;">
      <div class="flex justify-between">
        <div class="flex items-center mx-4">
          <div>
            <Button @click="getData(1)" class="bg-white border-0 text-gray-600" icon="pi pi-angle-left p-0" />
          </div>
          <div class="mx-2 font-bold capitalize">
            {{ tipeDateSelected === 1 ? dateSelected.locale('es').format('MMMM - YYYY') : dateSelected.locale('es').format('YYYY') }}
          </div>
          <div>
            <Button @click="getData(2)" class="bg-white border-0 text-gray-600" icon="pi pi-angle-right" />
          </div>
        </div>
        <Dropdown class="md:ml-auto mt-5 md:mt-0" v-model="tipeDateSelected" @change="getData()" :options="tipeDate" optionLabel="name" optionValue="value" placeholder="Seleccione" />
      </div>
      <div
      id="grafica"
      ref="elGrafica"
      style="height:315px;"
      class="w-full mt-4"
      />
      <div class="flex justify-between my-2">
        <div>
          <p>
            Total de procedimientos en el
            <span v-if="tipeDateSelected === 1">mes</span>
            <span v-if="tipeDateSelected === 2">año</span>
            de
            <span class="capitalize font-bold">{{ tipeDateSelected === 1 ? dateSelected.locale('es').format('MMMM') : dateSelected.locale('es').format('YYYY') }} - {{ procedimientosTotales }}</span>
          </p>
        </div>
        <div>
          <button class="text-blue-600 flex items-center" @click="$router.push({name:'pharmasan.administrativa.juridica.procesos-administrativos'})">
            Ver todas
            <i class="pi pi-arrow-circle-right ml-1"></i>
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import dayjs from 'dayjs'
import * as echarts from 'echarts'
import { computed, onMounted, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import DashboardService from '../../../services/dashboard.service'
// import derechosPeticionStore from '../../../store/derechosPeticion.store'
export default {
  name: 'ChartProcedimientosAdministrativos',
  setup () {
    const _DashboardService = ref(new DashboardService())
    const store = useStore()
    const isDark = computed(() => {
      return store.getters['main/darkMode']
    })

    const gradesRotation = ref(0)
    const positionLabel = ref('insideTop')
    const distanceLabel = ref(10)
    const loading = ref(false)
    const colorLabel = ref('white')
    const elGrafica = ref(null)
    let chart = reactive({})
    const chartData = ref([])
    const dateSelected = ref(dayjs())
    const tipeDateSelected = ref(1)
    const procedimientosTotales = ref(0)
    const tipeDate = ref([
    {
        name: 'Mes',
        value: 1
      },
      {
        name: 'Año',
        value: 2
      }
    ])

    const getData = (action) => {
      if (tipeDateSelected.value === 1) {
        action === 1 ? dateSelected.value = dateSelected.value.subtract(1, 'month') : dateSelected.value = dateSelected.value.add(1, 'month')
      }
      if (tipeDateSelected.value === 2) {
        action === 1 ? dateSelected.value = dateSelected.value.subtract(1, 'year') : dateSelected.value = dateSelected.value.add(1, 'year')
      }
      initChart()
    }

    const initChart = () => {
      gradesRotation.value = (window.innerWidth < 1000) ? 90 : 0
      positionLabel.value = (window.innerWidth < 1000) ? 'top' : 'top'
      distanceLabel.value = (window.innerWidth < 1000) ? 25 : 10
      colorLabel.value = (window.innerWidth < 1000 && !isDark.value) ? 'gray' : 'gray'
      chart = echarts.init(elGrafica.value, isDark.value ? 'dark' : 'light')
      // tipeDate.value === 1 ? { month: dayjs(dateSelected.value).format('YYYY-MM') } : { year: dayjs(dateSelected.value).format('YYYY') }
      _DashboardService.value.getProcedimientosAdministrativos(tipeDateSelected.value === 1 ? { month: dayjs(dateSelected.value).format('YYYY-MM') } : { year: dayjs(dateSelected.value).format('YYYY') }).then(({ data }) => {
        procedimientosTotales.value = data.derechos.respondidos + data.derechos.noRespondidos + data.requerimientos.respondidos + data.requerimientos.noRespondidos + data.quejas.respondidos + data.quejas.noRespondidos
        const option = {
          backgroundColor: isDark.value ? '#313A55' : '',
          grid: {
            left: 0,
            right: 15,
            bottom: 20,
            top: 50,
            containLabel: true
          },
          legend: {},
          tooltip: {},
          yAxis: {},
          dataset: {
            source: [
              ['product', 'Respondidos', 'En termino'],
              ['Derechos de petición', data.derechos.respondidos, data.derechos.noRespondidos],
              ['Requerimientos', data.requerimientos.respondidos, data.requerimientos.noRespondidos],
              ['Quejas', data.quejas.respondidos, data.quejas.noRespondidos]
            ]
          },
          xAxis: { type: 'category' },
          series: [{ type: 'bar' }, { type: 'bar' }]
        }
        chart.setOption(option)
      })
    loadEvents()
    }
    const loadEvents = () => {
      window.addEventListener('resize', (evt) => {
        gradesRotation.value = (evt.target.innerWidth < 1000) ? 90 : 0
        positionLabel.value = (evt.target.innerWidth < 1000) ? 'top' : 'top'
        distanceLabel.value = (evt.target.innerWidth < 1000) ? 25 : 10
        colorLabel.value = (window.innerWidth < 1000 && !isDark.value) ? 'gray' : 'gray'
        reloadChart()
        chart.resize()
      })
    }

    const reloadChart = () => {
      const option = {
        xAxis: { type: 'category' },
        series: [{ type: 'bar' }, { type: 'bar' }]
      }

      if (window.innerWidth < 1024) {
        option.xAxis.show = false
      }

      chart.setOption(option)
    }
    const setChartData = (values = []) => {
      chartData.value = values.data
    }

    onMounted(() => {
      initChart()
    })

    return {
      chart,
      loading,
      elGrafica,
      setChartData,
      reloadChart,
      getData,
      dateSelected,
      tipeDate,
      tipeDateSelected,
      procedimientosTotales
    }
  }
}
</script>

<style>
.pos-loading {
  height:350px;
  position:absolute;
  top:50%;
  transform:translateY(-50%);
  z-index:1;
  width:100%
}
</style>
