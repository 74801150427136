import { createStore } from 'vuex'
import DashboardService from '../services/dashboard.service'
const _DashboardService = new DashboardService()

const dashboardStore = {
  state: {
    tutelasMes: [],
    tutelasAño: [],
    derechosPeticion: [],
    cards: {
      tutelas: {
        total: null,
        anual: null
      },
      procedimientosAdministrativos: {
        total: null,
        anual: null
      },
      procesosJudiciales: {
        total: null,
        anual: null
      }
    }
  },
  getters: {
    _tutelasMes: (state) => state.tutelasMes,
    _tutelasAño: (state) => state.tutelasAño,
    _derechosPeticion: (state) => state.derechosPeticion,
    _cards: (state) => state.cards
  },
  mutations: {
    setTutelasByMonth (state, payload) {
      state.tutelasMes = payload
    },
    setTutelasByYear (state, payload) {
      state.tutelasAño = payload
    },
    setDerechosPeticion (state, payload) {
      state.derechosPeticion = payload
    },
    setCards (state, payload) {
      state.cards = payload
    }
  },
  actions: {
    getTutelasByMonth ({ commit }, date) {
      _DashboardService.getTutelasByMonth(date).then(({ data }) => {
        commit('setTutelasByMonth', data)
      })
    },
    getTutelasByYear ({ commit }, date) {
      _DashboardService.getTutelasByYear(date).then(({ data }) => {
        commit('setTutelasByYear', data)
      })
    },
    getDerechosPeticion ({ commit }, date) {
      _DashboardService.getDerechosPeticion(date).then(({ data }) => {
        commit('setDerechosPeticion', data)
      })
    },
    getCards ({ commit }, year) {
      _DashboardService.getCardsInfo(year).then(({ data }) => {
        commit('setCards', data)
      })
    }
  }
}
const store = createStore(dashboardStore)
export default {
  namespaced: true,
  ...store
}
