<template>
  <div class="grid grid-cols-12 gap-6" >
    <div class="col-span-12 2xl:col-span-9">
      <div class="grid grid-cols-12 gap-6">
        <!-- BEGIN: General Report -->
        <div class="col-span-12 mt-8">
          <div class="block md:flex md:justify-between">
            <div class="intro-y flex items-center h-10">
              <h2 class="text-lg font-medium truncate mr-5">Reporte general de procesos juridicos</h2>
            </div>
            <Dropdown v-model="yearGeneralReport" @change="getYears()" :showClear="true" :options="años"  placeholder="Año" />
          </div>
          <div class="grid grid-cols-12 gap-6 mt-5">
            <div class="col-span-12 sm:col-span-6 xl:col-span-4 intro-y">
              <div class="report-box zoom-in" style="height: 100%;">
                <div class="box p-5 h-100" style="height: 100%;">
                  <div class="flex">
                    <svg class="w-6 h-6 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"></path>
                    </svg>
                  </div>
                  <div class="flex items-baseline mt-6">
                    <div class="text-3xl font-medium leading-8">{{ yearGeneralReport ? quantityCards.tutelas.anual : quantityCards.tutelas.total }}</div>
                    <span class="text-md font-medium text-gray-400 mx-2">/ {{ yearGeneralReport ? 'Año' : 'Total' }}</span>
                  </div>
                  <div class="text-base text-slate-500 mt-1">No. Tutelas</div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-4 intro-y">
              <div class="report-box zoom-in" style="height: 100%;">
                <div class="box p-5" style="height: 100%;">
                  <div class="flex">
                    <svg class="w-6 h-6 text-orange-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 11.5V14m0-2.5v-6a1.5 1.5 0 113 0m-3 6a1.5 1.5 0 00-3 0v2a7.5 7.5 0 0015 0v-5a1.5 1.5 0 00-3 0m-6-3V11m0-5.5v-1a1.5 1.5 0 013 0v1m0 0V11m0-5.5a1.5 1.5 0 013 0v3m0 0V11"></path>
                    </svg>
                  </div>
                  <div class="flex items-baseline mt-6">
                    <div class="text-3xl font-medium leading-8">{{ yearGeneralReport ? quantityCards.procedimientosAdministrativos.anual : quantityCards.procedimientosAdministrativos.total }}</div>
                    <span class="text-md font-medium text-gray-400 mx-2">/ {{ yearGeneralReport ? 'Año' : 'Total' }}</span>
                  </div>
                  <div class="text-base text-slate-500 mt-1">No. Procedimientos administrativos</div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-4 intro-y">
              <div class="report-box zoom-in" style="height: 100%;">
                <div class="box p-5" style="height: 100%;">
                  <div class="flex">
                    <svg class="w-6 h-6 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"></path>
                    </svg>
                  </div>
                  <div class="flex items-baseline mt-6">
                    <div class="text-3xl font-medium leading-8">{{ yearGeneralReport ? quantityCards.procesosJudiciales.anual : quantityCards.procesosJudiciales.total }}</div>
                    <span class="text-md font-medium text-gray-400 mx-2">/ {{ yearGeneralReport ? 'Año' : 'Total' }}</span>
                  </div>
                  <div class="text-base text-slate-500 mt-1">No. Procesos Judiciales</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ChartTutelas class="col-span-12 lg:col-span-6"/>
        <ChartProcedimientosAdministrativosVue class="col-span-12 lg:col-span-6" />
        <ChartProcesosJudiciales class="col-span-12"/>
      </div>
    </div>
    <div class="col-span-12 2xl:col-span-3">
      <div class="2xl:border-l -mb-10 pb-10">
        <div class="2xl:pl-6">
          <Solicitues/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dashboardStore from '../../store/dashboard.store'
import ChartTutelas from './components/ChartTutelas.vue'
// import ChartDerechosPeticion from './components/ChartDerechosPeticion.vue'
// import ChartProcesos from './components/ChartProcesos.vue'
import ChartProcedimientosAdministrativosVue from './components/ChartProcedimientosAdministrativos.vue'
import ChartProcesosJudiciales from './components/ChartProcesosJudiciales.vue'
import Solicitues from './components/Solicitudes.vue'
import { computed, onMounted, ref } from 'vue'
import dayjs from 'dayjs'
export default {
  name: 'ProcesosJuridicosHome',
  components: {
    ChartTutelas,
    // ChartDerechosPeticion,
    // ChartProcesos,
    Solicitues,
    ChartProcedimientosAdministrativosVue,
    ChartProcesosJudiciales
  },
  setup () {
    const yearGeneralReport = ref(null)
    const años = ref([])
    // computed
    const quantityCards = computed(() => dashboardStore.getters._cards)
    // methods
    const getYears = () => {
      const hoy = dayjs()
      for (let i = 0; i <= hoy.diff('2017-06-05', 'year'); i++) {
        años.value.push(dayjs('2017-06-05').add(i, 'year').format('YYYY'))
      }
      dashboardStore.dispatch('getCards', ({ year: yearGeneralReport.value }))
    }

    onMounted(() => {
      getYears()
    })
    return {
      dayjs,
      yearGeneralReport,
      quantityCards,
      años,
      getYears
    }
  }
}
</script>
<style scoped>
</style>
