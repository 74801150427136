<template>
  <div
    class="col-span-12 md:col-span-6 xl:col-span-4 2xl:col-span-12 mt-3 2xl:mt-8"
  >
    <div class="intro-x flex items-center h-10">
      <h2 class="text-lg font-medium truncate mr-5">Solicitudes</h2>
    </div>
    <div class="mt-5">
      <div
        v-for="(solicitud, i) in solicitudes"
        :key="i"
        class="intro-x"
      >
        <div class="box px-5 py-3 mb-3 items-center zoom-in">
          <div class="ml-4 mr-4 text-justify">
            <div class="font-medium">
              {{ solicitud.tipoName }}
            </div>
            <div class="text-slate-500 text-xs mt-0.5">
              Solicitado a: {{ solicitud.solicitado.fullName }}
            </div>
            <div class="text-slate-500 text-xs mt-0.5">
              Fecha: {{ dayjs(solicitud.updatedAt).format('DD-MM-YYYY') }}
            </div>
            <div class="mt-2 text-justify" :class="solicitud.respuesta ? 'text-blue-600' : 'text-red-600'">
              respuesta: {{ solicitud.respuesta.substr(0, 100) }}
              <p v-if="solicitud.respuesta.length > 100">
                ...
              </p>
              <div >
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        class="intro-x w-full block text-center rounded-md py-3 border border-dotted border-slate-400 dark:border-darkmode-300 text-slate-500"
        @click="$router.push({name:'pharmasan.administrativa.juridica.solicitudes'})">
        Ver todas
      </button>
    </div>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import { computed, onMounted } from 'vue'
import solicitudesStore from '../../../store/solicitudes.store'
export default {
  name: 'Solicitudes',
  setup () {
    // computed
    const solicitudes = computed(() => solicitudesStore.getters.solicitudes.rows)
    onMounted(async () => {
      await solicitudesStore.dispatch('getPaginate', {
        offset: 0,
        page: 0,
        limit: 6
      })
    })
    return { solicitudes, dayjs }
  }
}
</script>
<style scoped>
</style>
