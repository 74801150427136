<template>
  <div class="col-span-12 lg:col-span-6 mt-8">
    <div class="intro-y block sm:flex items-center h-10">
      <h2 class="text-lg font-medium truncate mr-5">Tutelas</h2>
    </div>
    <div class="intro-y box p-5 mt-12 sm:mt-5">
      <div class="flex flex-col md:flex-row md:items-center">
        <div class="flex">
          <div>
            <div
              class="text-primary dark:text-slate-300 text-lg xl:text-xl font-medium"
            >
              {{ tutelas.respondidas }}
            </div>
            <div class="mt-0.5 text-slate-500">Respondidas</div>
          </div>
          <div
            class="w-px h-12 border border-r border-dashed border-slate-200 dark:border-darkmode-300 mx-4 xl:mx-5"
          ></div>
          <div>
            <div class="text-slate-500 text-lg xl:text-xl font-medium">
              {{ tutelas.noRespondidas }}
            </div>
            <div class="mt-0.5 text-slate-500">Sin responder</div>
          </div>
        </div>
        <div class="flex items-center mx-4">
          <div>
            <Button @click="getChartTutelaData(1)" class="bg-white border-0 text-gray-600" icon="pi pi-angle-left p-0" />
          </div>
          <div class="mx-2 font-bold capitalize">
            {{ chartTutelaSelected === 1 ? actualMonth.locale('es').format('MMMM - YYYY') : actualMonth.locale('es').format('YYYY') }}
          </div>
          <div>
            <Button @click="getChartTutelaData(2)" class="bg-white border-0 text-gray-600 hover:bg-purple-700" icon="pi pi-angle-right" />
          </div>
        </div>
        <Dropdown class="md:ml-auto mt-5 md:mt-0" v-model="chartTutelaSelected" @change="getChartTutelaData()" :options="optionDropdownChartTutelas" optionLabel="name" optionValue="value" placeholder="Seleccione" />
      </div>
      <div class="mt-4">
        <Chart type="line" :data="basicData" :options="basicOptions" />
      </div>
      <div class="flex justify-between my-2">
        <div>
          <p>
            Total de tutelas en el
            <span v-if="chartTutelaSelected === 1">mes</span>
            <span v-if="chartTutelaSelected === 2">año</span>
            de
            <span class="capitalize font-bold">{{ chartTutelaSelected === 1 ? actualMonth.locale('es').format('MMMM') : actualMonth.locale('es').format('YYYY') }} - {{ tutelas.todas }}</span>
          </p>
        </div>
        <div>
          <button class="text-blue-600 flex items-center" @click="$router.push({name:'pharmasan.administrativa.juridica.tutelas'})">
            Ver todas
            <i class="pi pi-arrow-circle-right ml-1"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, ref } from 'vue'
import dayjs from 'dayjs'
import groupBy from 'lodash/groupBy'
import DashboardService from './../../../services/dashboard.service'
export default {
  name: 'ChartTutelas',
  setup () {
    const _DashboardService = ref(new DashboardService())
    const chartTutelaSelected = ref(1)
    const labelsChartTutela = ref([])
    const datasetsChartTuela1 = ref([])
    const datasetsChartTuela2 = ref([])
    const tutelas = ref([])
    const actualMonth = ref(dayjs())
    // data
    const basicData = ref(
      {
        labels: labelsChartTutela,
        datasets: [
            {
                label: 'Tutelas respondidas',
                data: datasetsChartTuela1,
                fill: false,
                borderColor: '#42A5F5',
                tension: 0.4
            },
            {
                label: 'Tutelas sin responder',
                data: datasetsChartTuela2,
                fill: false,
                borderColor: '#FFA726',
                tension: 0.4
            }
        ]
      }
    )
    const basicOptions = ref(
        {
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            }
        }
    )
    const optionDropdownChartTutelas = ref([
      {
        name: 'Mes',
        value: 1
      },
      {
        name: 'Año',
        value: 2
      }
    ])
    // methods

    const getChartTutelaData = (monthBefore) => {
      if (chartTutelaSelected.value === 1) {
        if (monthBefore === 1) {
          actualMonth.value = actualMonth.value.subtract(1, 'month')
        }
        if (monthBefore === 2) {
          actualMonth.value = actualMonth.value.add(1, 'month')
        }
        _DashboardService.value.getTutelasByMonth({ mes: actualMonth.value.format('MM-YYYY') }).then(({ data }) => {
          tutelas.value.respondidas = data.respondidas.length
          tutelas.value.noRespondidas = data.noRespondidas.length
          tutelas.value.todas = data.todas.length
          const tutelasMesRespondidas = groupBy(data.todas.filter(a => a.respuesta), 'fechaRecepcion')
          const tutelasMesNoRespondidas = groupBy(data.todas.filter(a => !a.respuesta), 'fechaRecepcion')
          let days = 0
          if (dayjs().format('MM-YYYY') === actualMonth.value.format('MM-YYYY')) {
            days = parseInt(dayjs().diff(dayjs().startOf('month'), 'day', true)) + 2
          } else {
            days = actualMonth.value.daysInMonth() + 1
          }
          const array1 = []
          const array2 = []
          const label = []
          for (let i = 1; i < days; i++) {
            label.push(i)
            let temp = 0
            for (var key in tutelasMesNoRespondidas) {
              // eslint-disable-next-line no-prototype-builtins
              if (tutelasMesNoRespondidas.hasOwnProperty(key)) {
                // eslint-disable-next-line no-unused-expressions
                i === parseInt(dayjs(key).format('DD')) ? temp = tutelasMesNoRespondidas[key].length : ''
              }
            }
            temp > 0 ? array2.push(temp) : array2.push(0)
          }
          for (let i = 1; i < days; i++) {
            let temp = 0
            // eslint-disable-next-line no-redeclare
            for (var key in tutelasMesRespondidas) {
              // eslint-disable-next-line no-prototype-builtins
              if (tutelasMesRespondidas.hasOwnProperty(key)) {
                // eslint-disable-next-line no-unused-expressions
                i === parseInt(dayjs(key).format('DD')) ? temp = tutelasMesRespondidas[key].length : ''
              }
            }
            temp > 0 ? array1.push(temp) : array1.push(0)
          }
          console.log(tutelasMesRespondidas, tutelasMesNoRespondidas, days)
          labelsChartTutela.value = label
          datasetsChartTuela1.value = array1
          datasetsChartTuela2.value = array2
        })
      } else {
        if (monthBefore === 1) {
          actualMonth.value = actualMonth.value.subtract(1, 'year')
        }
        if (monthBefore === 2) {
          actualMonth.value = actualMonth.value.add(1, 'year')
        }
        _DashboardService.value.getTutelasByYear({ año: actualMonth.value.format('YYYY') }).then(({ data }) => {
          tutelas.value.respondidas = data.respondidas.length
          tutelas.value.noRespondidas = data.noRespondidas.length
          tutelas.value.todas = data.todas.length
          data.todas.map(a => {
            a.fechaRecepcion = dayjs(a.fechaRecepcion).month()
          })
          const tutelasAñoRespondidas = groupBy(data.todas.filter(a => a.respuesta), 'fechaRecepcion')
          const tutelasAñoNoRespondidas = groupBy(data.todas.filter(a => !a.respuesta), 'fechaRecepcion')
          let days = 0
          if (dayjs().format('YYYY') === actualMonth.value.format('YYYY')) {
            days = parseInt(dayjs().diff(dayjs().startOf('year'), 'month', true)) + 1
          } else {
            days = 12
          }
          const array1 = []
          const array2 = []
          const label = []
          for (let i = 0; i < days; i++) {
            label.push(dayjs().month(i).locale('es').format('MMMM'))
            let temp1 = 0
            let temp2 = 0
            for (var key in tutelasAñoRespondidas) {
              // eslint-disable-next-line no-prototype-builtins
              if (tutelasAñoRespondidas.hasOwnProperty(key)) {
                // eslint-disable-next-line no-unused-expressions
                i === parseInt(dayjs(key).format('MM')) ? temp1 = tutelasAñoRespondidas[key].length : ''
              }
            }
            // eslint-disable-next-line no-redeclare
            for (var key in tutelasAñoNoRespondidas) {
              // eslint-disable-next-line no-prototype-builtins
              if (tutelasAñoNoRespondidas.hasOwnProperty(key)) {
                // eslint-disable-next-line no-unused-expressions
                i === parseInt(dayjs(key).format('MM')) ? temp2 = tutelasAñoNoRespondidas[key].length : ''
              }
            }
            console.log(tutelasAñoRespondidas, tutelasAñoNoRespondidas, temp1, temp2)
            temp1 > 0 ? array1.push(temp1) : array1.push(0)
            temp2 > 0 ? array2.push(temp2) : array2.push(0)
          }
          labelsChartTutela.value = label
          datasetsChartTuela1.value = array1
          datasetsChartTuela2.value = array2
        })
      }
    }

    onMounted(() => {
      getChartTutelaData()
    })
    return {
      basicData,
      basicOptions,
      getChartTutelaData,
      optionDropdownChartTutelas,
      chartTutelaSelected,
      labelsChartTutela,
      dayjs,
      datasetsChartTuela1,
      datasetsChartTuela2,
      tutelas,
      actualMonth
    }
  }
}
</script>
<style scoped>
</style>
